@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-secondary text-neutral-400 bg-[#e5e5e5];
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-primary text-neutral-500;
  }
  .h1 {
    @apply text-[40px] leading-[40px] lg:text-[60px] lg:leading-[62px] font-extrabold;
  }
  .h2 {
    @apply text-[32px] leading-[40px] lg:text-[50px] lg:leading-[50px] font-extrabold;
  }
  .h3 {
    @apply text-[38px] leading-[46px] font-extrabold;
  }
  .h4 {
    @apply text-[30px] leading-[46px] font-extrabold;
  }
  .h5 {
    @apply text-[24px] leading-[32px] font-extrabold;
  }
  .h6 {
    @apply text-[18px] leading-[28px] font-bold;
  }
  .text-body-sm {
    @apply font-secondary font-normal text-[16px] leading-[26px];
  }
  .text-body-md {
    @apply font-secondary font-normal text-[20px] leading-[32px];
  }
  .text-body-lg {
    @apply font-secondary font-normal text-[24px] leading-[34px];
  }
  .btn {
    @apply flex justify-center items-center rounded-[1px];
  }
  .btn-sm {
    @apply font-primary font-bold uppercase text-[12px] leading-[18px] h-[42px] px-[30px] tracking-[1px];
  }
  .btn-lg {
    @apply font-primary font-bold uppercase text-[14px] leading-[18px] h-[46px] px-[40px] tracking-[1px];
  }
  .btn-primary {
    @apply bg-primary-200 text-neutral-100 hover:bg-primary-200/90 transition;
  }
  .btn-secondary {
    @apply bg-white text-neutral-500 hover:bg-white/90 transition;
  }
  .link {
    @apply text-neutral-500 text-sm uppercase font-extrabold tracking-[1px];
  }
  .section {
    @apply pb-[80px] md:pb-[110px] lg:pb-[180px];
  }
  .section-title-group {
    @apply flex items-center justify-center -space-x-20 mb-[50px] lg:mb-[80px];
  }
  .section-title {
    @apply flex -space-x-4;
  }
}

.fixedwhatapp {
  position: fixed;
  width: 200px;
  height: 45px;
  margin: 0;
  padding: 0;
  top: 66%;
  right: -156px;
  z-index: 999;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  background: #25d366;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s
}

.fixedwhatapp a {
  display: block;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  font-weight: 600;
  letter-spacing: .6px;
  text-decoration: none;
  color: #fff;
  margin: 0;
  padding: 0;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s
}

.fixedwhatapp a span {
  width: 45px;
  height: 45px;
  float: left;
  background: #25d366;
  font-size: 25px;
  margin: 0 15px 0 0;
  text-align: center;
  line-height: 45px;
  color: #fff
}

.fixedwhatapp a p {
  margin: 0;
  padding: 7px;
  color: #fff;
  padding-top: 14px
}

.fixedwhatapp a svg {
  color: #fff;
  -webkit-animation: flipInY 1.25s infinite;
  -moz-animation: flipInY 1.25s infinite;
  -ms-animation: flipInY 1.25s infinite;
  animation: flipInY 1.25s infinite
}

.fixedwhatapp:hover {
  right: 0
}
